/** @jsxImportSource @emotion/react */
import { cx } from '@emotion/css';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  size?: number;
  className?: string;
};

const Icon = ({ children, className, size = 24 }: Props) => {
  return (
    <span css={{ display: 'flex', '& svg': { fontSize: size } }} className={cx(className)}>
      {children}
    </span>
  );
};

export default Icon;
