import { Button } from '@mui/material';
import { css } from '@emotion/react';
import { TOOLBAR_COLORS, TOOLBAR_DIMENSION, TOOLBAR_SPACING, TOOLBAR_STATE } from '../../helpers/toolbarStyleSettings';
import { MouseEvent } from 'react';

const getBackgroundColor = (isMenuOpen?: boolean) => {
  if (isMenuOpen) {
    return {
      color: 'inherit',
      backgroundColor: TOOLBAR_COLORS.focusColor,
    };
  }
  return TOOLBAR_STATE;
};

const classes = {
  button: (width: number, isMenuOpen?: boolean) =>
    css({
      textTransform: 'capitalize',
      alignItems: 'center',
      minWidth: width,
      fontFamily: 'inherit',
      border: 'none',
      cursor: 'pointer',
      height: TOOLBAR_DIMENSION.height,
      padding: TOOLBAR_SPACING.padding,
      borderRadius: TOOLBAR_SPACING.borderRadius,
      ...getBackgroundColor(isMenuOpen),
    }),
};

interface SpMuiButtonProps {
  onClickAction: (event: MouseEvent<HTMLElement>) => void;
  isActive?: boolean;
  isMenuOpen?: boolean;
  width: number;
  testId: string;
  children: React.ReactNode;
}

const PyMuiButton = ({ onClickAction, width, children, isMenuOpen, testId }: SpMuiButtonProps) => {
  return (
    <Button
      data-testid={testId}
      type="button"
      onClick={onClickAction}
      sx={classes.button(width, isMenuOpen)}
      variant="text"
      color="inherit"
    >
      {children}
    </Button>
  );
};

export default PyMuiButton;
